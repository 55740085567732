<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="150px" @submit.native.prevent @keyup.enter.native="search()">
            <el-form-item prop="resourceName" label="元数据模板名称：">
                <el-input v-model.trim="searchForm.resourceName" maxlength="50" placeholder="请输入元数据模板名称"
                          clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()" size="small">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()" size="small">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r p_r2">
                <el-button icon='el-icon-plus' type="primary" size="small" v-show="hasPermissionButton('collection:templateManage:add')"
                           @click="addEdit(0 )">新增
                </el-button>
            </el-form-item>
        </el-form>

        <div class="bg-white" style="height: calc(100% - 74px);">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 330px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <!--            <el-table-column :reserve-selection="true" type="selection" width="50"/>-->
                <!--            <el-table-column label="序号" type="index" width="50"></el-table-column>-->
                <el-table-column prop="resourceName" label="模板名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="interfaceHeight" label="界面高度" show-overflow-tooltip></el-table-column>
                <el-table-column prop="interfaceWidth" label="界面宽度" show-overflow-tooltip></el-table-column>
                <el-table-column prop="layoutMethod" label="布局方式" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.layoutMethod == 0 ? '上下' : '左右' }}
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="模板描述" show-overflow-tooltip></el-table-column>
                <el-table-column prop="type" label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("resource_type", scope.row.type, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="scope" label="应用范围" show-overflow-tooltip></el-table-column>
                <el-table-column prop="isEnable" label="启用状态" width="100px">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.isEnable"
                                :active-value="'0'"
                                :inactive-value="'1'"
                                @change="setIsEnable(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateManage:edit')"
                                   @click="addEdit(2,scope.row)">修改
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateManage:copy')"
                                   @click="copyData(scope.row)">复制
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateManage:correlation')"
                                   @click="correlation(scope.row)">关联元数据
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateManage:delete')"
                                   @click="del(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <add-template ref="addTemplate" @refreshList="search()"></add-template>
        <correlation-data ref="correlationData" @refreshList="search()"></correlation-data>
    </div>
</template>

<script>
import XEUtils from 'xe-utils'
import AddTemplate from "@/views/modules/dyyg/metadata/addTemplate.vue";
import CorrelationData from "@/views/modules/dyyg/metadata/correlationData.vue";

export default {
    components: {
        CorrelationData,
        AddTemplate
    },
    data() {
        return {
            searchForm: {
                resourceName: '',
            },

            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },
    mounted() {
        this.search()
    },
    methods: {
        // 查询
        search(num) {
            if (num) {
                this.current = 1
            }
            this.loading = true
            this.$axios(this.api.original.originalList, {
                current: this.current,
                size: this.size,
                ...this.searchForm,
                isType:0,
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.current--;
                        this.search()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //关联元数据
        correlation(row) {
            this.$refs.correlationData.init(row)
        },

        //复制
        copyData(row) {
            this.$confirm(`确定复制所选项模板吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.originalSave, {
                    ...row,
                }, "get").then((data) => {
                    if (data && data.status) {
                        this.$message.success('复制成功')
                        this.search()
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            })
        },

        // 删除
        del(row) {
            this.$confirm(`确定删除所选项模板吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.originalRemoveById + row.id, {}, "delete").then((data) => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.search()
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            })
        },

        //启用禁用
        setIsEnable(row) {
            this.$axios(this.api.original.originalEditEnable, {
                id: row.id,
                type: row.isEnable,
            }, "get").then((data) => {
                if (data && data.status) {
                    this.$message.success('修改成功')
                    this.search()
                } else {
                    this.$message.error(res.msg)
                }
            });
        },

        // 0新增 1查看 2修改
        addEdit(num, row) {
            this.$refs.addTemplate.init(num, row)
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.search(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.search('', 2);
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        //重置
        reset() {
            this.$refs.searchForm.resetFields();
            this.search()
        },
    }
}
</script>

<style scoped>

</style>
